// src/components/Footer.jsx

import React from "react";
import { Link } from "react-router-dom";
import { HashLink } from "react-router-hash-link";
import "./styling/Footer.css";

const Footer = () => {
  return (
    <footer className="footer">
      <div className="footer-content">
        {/* Contact Section */}
        <div className="footer-links">
          <h4>Contact</h4>
          <ul>
            <li>
              <Link to="/contact">Contact Us</Link>
            </li>
            {/* <li>
              <Link to="/privacy">Privacy Policy</Link>
            </li>
            <li>
              <Link to="/terms">Terms of Service</Link>
            </li> */}
          </ul>
        </div>

        {/* Web Development Section */}
        <div className="footer-links">
          <h4>Web Development</h4>
          <ul>
            <li>
              <Link to="/solutions/web-development">Overview</Link>
            </li>
            <li>
              <HashLink smooth to="/solutions/web-development#services">
                Our Services
              </HashLink>
            </li>

          </ul>
        </div>

        {/* App Development Section */}
        <div className="footer-links">
          <h4>App Development</h4>
          <ul>
            <li>
              <Link to="/solutions/mobile-app-development">Overview</Link>
            </li>
            <li>
              <HashLink smooth to="/solutions/mobile-app-development#services">
                Our Services
              </HashLink>
            </li>

          </ul>
        </div>

        {/* Custom Software Solutions Section */}
        <div className="footer-links">
          <h4>Custom Software Solutions</h4>
          <ul>
            <li>
              <Link to="/solutions/custom-solutions">Overview</Link>
            </li>
            <li>
              <HashLink smooth to="/solutions/custom-solutions#services">
                Our Services
              </HashLink>
            </li>
            <li>
              <Link to="/contact">Contact Us</Link>
            </li>
          </ul>
        </div>
      </div>

      {/* Footer Bottom */}
      <div className="footer-bottom">
        &copy; {new Date().getFullYear()} Clevr Software. All rights reserved.
      </div>
    </footer>
  );
};

export default Footer;
