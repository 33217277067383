// src/components/DemoForm.js
import React from "react";
import { useForm, ValidationError } from "@formspree/react";
import "./styling/DemoForm.css";
import {
 UserOutlined,
 RedEnvelopeOutlined,
 BuildOutlined,
 PhoneOutlined,
 CommentOutlined,
} from "@ant-design/icons";

const DemoForm = () => {
 const [state, handleSubmit] = useForm("xjkvrqqk"); // Replace with your Formspree ID

 if (state.succeeded) {
  return (
   <div style={{padding: 150, height: "60vh" , backgroundColor: "#fff"}}>
    <div className="form-success">
     <h2>🎉 Sent Successfully</h2>
     <p>
      Your request has been submitted successfully. We'll reach out to you
      shortly.
     </p>
    </div>
   </div>
  );
 }

 return (
  <div className="form-container">
   <div className="form-header">
    <h1>Get a Demo</h1>
    <p>Fill out the form below to schedule your demo.</p>
   </div>
   <form onSubmit={handleSubmit} className="demo-form">
    <div className="form-group">
     <label htmlFor="name">
      <UserOutlined className="icon" />
      Full Name
     </label>
     <input
      id="name"
      type="text"
      name="name"
      required
      placeholder="Enter your full name"
     />
     <ValidationError prefix="Name" field="name" errors={state.errors} />
    </div>

    <div className="form-group">
     <label htmlFor="email">
      <env className="icon" />
      Email Address
     </label>
     <input
      id="email"
      type="email"
      name="email"
      required
      placeholder="Enter your email address"
     />
     <ValidationError prefix="Email" field="email" errors={state.errors} />
    </div>

    <div className="form-group">
     <label htmlFor="company">
      <BuildOutlined className="icon" />
      Company
     </label>
     <input
      id="company"
      type="text"
      name="company"
      required
      placeholder="Enter your company name"
     />
     <ValidationError prefix="Company" field="company" errors={state.errors} />
    </div>

    <div className="form-group">
     <label htmlFor="phone">
      <PhoneOutlined className="icon" />
      Phone Number
     </label>
     <input
      id="phone"
      type="tel"
      name="phone"
      required
      placeholder="Enter your phone number"
     />
     <ValidationError prefix="Phone" field="phone" errors={state.errors} />
    </div>

    <div className="form-group">
     <label htmlFor="message">
      <CommentOutlined className="icon" />
      Message
     </label>
     <textarea
      id="message"
      name="message"
      required
      placeholder="How can we help you?"
     ></textarea>
     <ValidationError prefix="Message" field="message" errors={state.errors} />
    </div>

    <button type="submit" disabled={state.submitting}>
     {state.submitting ? "Submitting..." : "Request Demo"}
    </button>
   </form>
  </div>
 );
};

export default DemoForm;
