// src/components/Navbar.js
import React, { useState, useEffect } from 'react';
import { Link as RouterLink, useLocation } from 'react-router-dom';
import { FaBars, FaTimes } from 'react-icons/fa';
import './styling/Navbar.css';

function Navbar() {
  const [scrolled, setScrolled] = useState(() => window.scrollY > 50);
  const [menuOpen, setMenuOpen] = useState(false);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const location = useLocation();
  const [current, setCurrent] = useState(getCurrentKey(location.pathname));

  const isHomePage = location.pathname === '/';

  useEffect(() => {
    setCurrent(getCurrentKey(location.pathname));
  }, [location.pathname]);

  useEffect(() => {
    const handleScroll = () => {
      setScrolled(window.scrollY > 50);
    };

    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener('scroll', handleScroll);
    window.addEventListener('resize', handleResize);
    handleScroll(); // Initialize scroll state

    return () => {
      window.removeEventListener('scroll', handleScroll);
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const isMobile = windowWidth <= 768;
  const logoColor = scrolled || !isHomePage ? '#000' : '#000';

  function getCurrentKey(pathname) {
    if (pathname === '/') return '/';
    else if (
      pathname.startsWith('/solutions/web-development') ||
      pathname.startsWith('/solutions/mobile-app-development') ||
      pathname.startsWith('/solutions/custom-solutions')
    )
      return '/solutions';
    else if (pathname.startsWith('/contact')) return '/contact';
    else return '';
  }

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  const closeMenu = () => {
    setMenuOpen(false);
  };

  return (
    <header
      className={`navbar-nav ${scrolled ? 'navbar-scrolled-nav' : ''} ${
        isHomePage ? '' : 'navbar-not-home-nav'
      }`}
    >
      <div className="logo-nav">
        <RouterLink to="/" style={{ color: logoColor }}>
          Clevr
        </RouterLink>
      </div>
      {isMobile ? (
        <>
          <div className="mobile-menu-icon-nav" onClick={toggleMenu}>
            {menuOpen ? (
              <FaTimes style={{ color: logoColor, zIndex: 10000 }} />
            ) : (
              <FaBars style={{ color: logoColor, zIndex: 10000 }} />
            )}
          </div>
          <nav className={`mobile-menu-nav ${menuOpen ? 'open' : ''}`}>
            <ul>
              <li>
                <RouterLink to="/" onClick={closeMenu}>
                  Home
                </RouterLink>
              </li>
              <li>
                <RouterLink
                  to="/solutions/web-development"
                  onClick={closeMenu}
                >
                  Web Development
                </RouterLink>
              </li>
              <li>
                <RouterLink
                  to="/solutions/mobile-app-development"
                  onClick={closeMenu}
                >
                  Mobile App Development
                </RouterLink>
              </li>
              <li>
                <RouterLink
                  to="/solutions/custom-solutions"
                  onClick={closeMenu}
                >
                  Custom Software Solutions
                </RouterLink>
              </li>
              <li>
                <RouterLink to="/contact" onClick={closeMenu}>
                  Get Demo
                </RouterLink>
              </li>
            </ul>
          </nav>
        </>
      ) : (
        <nav className="desktop-menu-nav">
          <ul className="menu-nav">
            <li className={current === '/' ? 'active' : ''}>
              <RouterLink to="/">Home</RouterLink>
            </li>
            <li
              className={`solutions-menu-item-nav ${
                current === '/solutions' ? 'active' : ''
              }`}
            >
              <span>Solutions</span>
              <ul className="dropdown-menu-nav">
                <li>
                  <RouterLink to="/solutions/web-development">
                    Web Development
                  </RouterLink>
                </li>
                <li>
                  <RouterLink to="/solutions/mobile-app-development">
                    Mobile App Development
                  </RouterLink>
                </li>
                <li>
                  <RouterLink to="/solutions/custom-solutions">
                    Custom Software Solutions
                  </RouterLink>
                </li>
              </ul>
            </li>
          </ul>
          <div className="contact-button-nav">
            <RouterLink to="/contact">
              <button className="contact-button">Get Demo</button>
            </RouterLink>
          </div>
        </nav>
      )}
    </header>
  );
}

export default Navbar;
