// src/App.js
import React, { Suspense, lazy } from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  useLocation,
} from "react-router-dom";
import Layout from "./components/Layout";
import ScrollToTop from "./components/ScrollToTop";
import Navbar from "./components/Navbar";
import "antd/dist/reset.css";
import "./App.css";
import GetDemoForm from "./pages/DemoForm";
import PrivacyPolicy from "./pages/PrivacyPolicy";


// Lazy-loaded components
const Home = lazy(() => import("./pages/Home"));
const Contact = lazy(() => import("./pages/DemoForm"));
const WebDevelopment = lazy(() => import("./pages/WebDevelopment"));
const MobileAppDevelopment = lazy(() => import("./pages/MobileAppDevelopment"));
const CloudSolutions = lazy(() => import("./pages/CustomSoftwareSolutions"));
const ServiceList = lazy(() => import("./pages/ServicesPage"));

const RoutesWithoutAnimation = () => {
  const location = useLocation();

  return (
    <Routes location={location} key={location.pathname}>
      {/* Home Route */}
      <Route
        path="/"
        element={
          <Layout>
            <Home />
          </Layout>
        }
      />

      {/* Service List Route */}
      <Route
        path="/solutions"
        element={
          <Layout>
            <ServiceList />
          </Layout>
        }
      />

      {/* Web Development Route */}
      <Route
        path="/solutions/web-development"
        element={
          <Layout>
            <WebDevelopment />
          </Layout>
        }
      />

      {/* Mobile App Development Route */}
      <Route
        path="/solutions/mobile-app-development"
        element={
          <Layout>
            <MobileAppDevelopment />
          </Layout>
        }
      />

      {/* Custom Software Solutions Route */}
      <Route
        path="/solutions/custom-solutions"
        element={
          <Layout>
            <CloudSolutions />
          </Layout>
        }
      />

      {/* Contact Route */}
      <Route
        path="/contact"
        element={
          <Layout>
            <GetDemoForm />
          </Layout>
        }
      />

      {/* Privacy Policy Route */}
      {/* <Route
        path="/privacy"
        element={
          <Layout>
            <PrivacyPolicy />
          </Layout>
        }
      /> */}

      {/* Fallback Route (Page Not Found) */}
      <Route
        path="*"
        element={
          <Layout>
            <div style={{ padding: "15rem", textAlign: "center", height: "80vh" }}>
              <h1><strong>404 - Page Not Found</strong></h1>
              <h2>The page you're looking for doesn't exist.</h2>
              <h3>Return to the <a style={{fontWeight: 700, textDecoration: "none", color: "blueviolet"}} href="/">homepage</a>.</h3>
            </div>
          </Layout>
        }
      />
    </Routes>
  );
};

function App() {
  return (
    <Router>
      <Navbar />
      <ScrollToTop />
      <Suspense
        fallback={
          <div style={{ textAlign: "center", padding: "15rem" }}>Loading...</div>
        }
      >
        <RoutesWithoutAnimation />
      </Suspense>
    </Router>
  );
}

export default App;
